@import url(https://use.typekit.net/dwt8vwy.css);
body {
  margin: 0;
  padding: 0;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.debug-text {
  font-size: 0.8em;
  /* color: gray */
}

.App {
  font-size: 24px;
  font-family: proxima-nova-condensed, sans-serif; }

.order {
  padding: 0em 0.2em;
  margin: 0.2em 0.2em;
  border: 2px solid black;
  border-radius: 6px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
  background: white;
  /* border: 1px solid black; */
  /* border-bottom: 2px solid black; */ }
  .order .order-item-todos.pane {
    border: 2px solid gray;
    border-radius: 0.5em;
    background: #bdecff;
    padding: 0;
    overflow: hidden; }
  .order .order-item-todos.complete {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%); }
    .order .order-item-todos.complete .todo-list-header {
      background: none;
      color: #002b36; }
  .order .order-item-todos .todo-list-header {
    font-weight: 600;
    padding: 0.3em;
    background: #dc322f;
    color: white; }
  .order .order-item-todos .todo-item {
    padding: 0.3em; }

.order.test-order {
  /* text-decoration: line-through; */
  /* text-decoration-color: red; */ }

.order.scheduled {
  /* border-top: 8px green solid; */
  /* border-bottom: 8px green solid; */
  /* border-left:  */ }

.order-number {
  margin: 0.5em 0; }

.order-number__number {
  font-weight: 600; }

.customer-info {
  margin: 0.5em 0; }

.order-items-list {
  /* padding: 0.5em 0 0 0.5em; */
  /* margin-left: 0.5em; */
  /* list-style: decimal; */ }

.order-item {
  /* display: list-item; */
  /* border-left: 0.2em solid #009a00; */
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: flex-start; }

.order-item .legacy-quantity {
  display: block;
  background: blue;
  padding: 0.5em;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  margin: 0 0.2em; }

.order-item:nth-of-type(2n+1) {
  background: #eee; }

.order-item-details {
  /* font-weight: 600; */
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end; }

.price {
  font-size: 0.8em;
  flex: 1 1;
  text-align: right; }

/* .order-item-details > * {
  margin-right: 0.2em;
} */
.note {
  font-style: italic;
  /* font-size: 1.1em; */
  /* border-left: 0.1em solid #38db00; */
  text-decoration: underline;
  /* padding-left: 0.1em */ }

.actions {
  margin: 0.5em 0; }

.category {
  font-weight: 600;
  color: #697178;
  /* text-transform: lowercase; */
  font-size: 0.8em;
  /* background: #cbc6d2; */ }

.category-and-name {
  display: flex;
  flex-direction: column; }

.thumbnail {
  width: 75px;
  margin-right: 0.1em; }

.thumbnail > img {
  width: 100%;
  border-radius: 5px; }

.text {
  flex: 1 1; }

.filters {
  display: flex; }

.delivery-info {
  font-size: 0.8em;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: baseline;
  grid-column-gap: 0.2em; }

.order-totals {
  font-size: 0.7em;
  justify-content: end;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.15em 1em; }

.label {
  grid-column: 1/2; }

.divider {
  grid-column: 1/3;
  border-bottom: 2px dashed black; }

.grand-total {
  /* border-top: 2px solid black; */
  font-size: 1.2em;
  font-weight: 600; }

.cart-type {
  display: inline-block;
  /* background: yellow; */
  margin-right: 0.1em;
  padding: 0 0.2em;
  border-radius: 0.3em; }

/* Scheduled */
.scheduled-text {
  background: #eee;
  /* padding: 0 0.2em; */ }

.warning-text {
  color: white;
  font-weight: 600;
  background: #d10000;
  border-radius: 0.2em;
  padding: 0 0.2em; }

.legacy-actions {
  text-align: right; }

.modal-ios-audio-enabler {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 1.3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1em;
  background: rgba(0,0,0,0.5);
  text-align: center;
}
.modal-ios-audio-enabler .instructions {
  line-height: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;
  background: #bdc4b5;
}
.modal-ios-audio-enabler .alarm {
  width: 1em;
  -webkit-animation: hithere 2.5s ease infinite;
          animation: hithere 2.5s ease infinite;
}
.modal-ios-audio-enabler .yes-no {
  margin-top: 1em;
}
.modal-ios-audio-enabler .yes-no button {
  padding: 0.5em 1em;
}
@-webkit-keyframes hithere {
  30% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  40%, 60% {
    -webkit-transform: rotate(-20deg) scale(1.2);
            transform: rotate(-20deg) scale(1.2);
  }
  50% {
    -webkit-transform: rotate(20deg) scale(1.2);
            transform: rotate(20deg) scale(1.2);
  }
  70% {
    -webkit-transform: rotate(0deg) scale(1.2);
            transform: rotate(0deg) scale(1.2);
  }
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes hithere {
  30% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  40%, 60% {
    -webkit-transform: rotate(-20deg) scale(1.2);
            transform: rotate(-20deg) scale(1.2);
  }
  50% {
    -webkit-transform: rotate(20deg) scale(1.2);
            transform: rotate(20deg) scale(1.2);
  }
  70% {
    -webkit-transform: rotate(0deg) scale(1.2);
            transform: rotate(0deg) scale(1.2);
  }
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}



.modgroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.1em
}


.group-name {
  /* font-weight: 600; */
  /* color: #697178; */
  /* text-transform: lowercase; */
  font-size: 0.6em;
  line-height: 0.7em;
  text-transform: lowercase;
  text-decoration: underline;
}

.modifier:after {
  content: ', ';
  color: green;
}
.modifier {
  display: flex;
  align-items: flex-start;
}
.modifier-price {
  font-size: 0.5em;
  font-weight: 600;
  line-height: 1.3em;
  /* background: green; */
  /* padding: 0 0.1em; */
  /* border-radius: 0.3em; */
  color: red;
}
.driver-request {

}

.driver-request__status {

}

.payment {
  font-size: 0.7em;
  /* background: #ccff66; */
  /* color: white; */
  padding: 0.2em 0.3em;
  text-align: right;
}

@charset "UTF-8";
.order-rep.status.done:before {
  content: "✅"; }

.order-rep.status .tasks {
  display: flex; }

.order-rep.status .task {
  border: 1px solid gray;
  border-radius: 0.4em;
  display: inline-block;
  margin: 0em 0.2em;
  overflow: hidden; }

.order-rep.status .task-name {
  font-weight: 600;
  background: #4762ff;
  color: white;
  display: inline-block;
  padding: 0.1em 0.2em;
  box-sizing: border-box;
  border-right: 1px solid gray; }

.order-rep.status .task-status {
  padding: 0.1em 0.2em;
  display: inline-block; }

.order-ready-estimate-editor .button-group {
  display: flex;
  align-items: stretch; }

.order-ready-estimate-editor button {
  font-size: 1.2em;
  width: 2em;
  border: none;
  background: #bbf7e4; }

.order-ready-estimate-editor input {
  width: 2em;
  text-align: center;
  border: none;
  background: #ecf6e0; }

.minutes-delay-button {
  display: inline-block;
  margin-right: 0.3em; }

.foh__order-details .order-item-todos.pane {
  background: #bdecff;
  padding: 0;
  overflow: hidden; }

.foh__order-details .order-item-todos.complete {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%); }
  .foh__order-details .order-item-todos.complete .todo-list-header {
    background: none;
    color: #002b36; }

.foh__order-details .order-item-todos .todo-list-header {
  font-weight: 600;
  padding: 0.3em;
  background: #dc322f;
  color: white; }

.foh__order-details .order-item-todos .todo-item {
  padding: 0.3em; }

.pos-foh-screen {
  height: 100%; }

.top-bar {
  z-index: 1000;
  position: -webkit-sticky;
  position: sticky;
  top: 0; }

.orders-nav {
  position: fixed;
  left: 0;
  height: 100%;
  padding-bottom: 10em;
  box-sizing: border-box;
  border: 1px solid gray;
  overflow-y: scroll;
  width: 125px; }
  @media screen and (max-width: 460px) {
    .orders-nav {
      width: 60px; } }

.right-screen {
  margin-left: 125px; }
  @media screen and (max-width: 460px) {
    .right-screen {
      margin-left: 60px;
      font-family: proxima-nova-extra-condensed, sans-serif; } }

@media screen and (max-width: 460px) {
  .thumbnail {
    display: none; } }

@media screen and (min-width: 1000px) {
  .orders-nav {
    width: 200px; }
  .right-screen {
    margin-left: 200px; } }

.orders-nav {
  font-family: proxima-nova-extra-condensed, sans-serif;
  background-color: #fdf6e3;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='119' height='119' viewBox='0 0 100 100'%3E%3Cg stroke='%2393a1a1' stroke-width='0' %3E%3Crect fill='%23eee8d5' x='-60' y='-60' width='110' height='240'/%3E%3C/g%3E%3C/svg%3E"); }
  .orders-nav .section-header {
    background: #cb4b16;
    color: #fdf6e3;
    border-top: 2px solid #073642;
    border-left: none;
    border-right: none;
    text-align: center; }
    .orders-nav .section-header button {
      text-decoration: underline;
      color: #fdf6e3;
      display: block;
      border: none;
      font: inherit;
      background: #268bd2;
      width: 100%; }

a.orders-nav__item {
  overflow: hidden;
  background: #eee8d5;
  color: #002b36;
  padding: 0.2em 0;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid #002b36;
  line-height: 1em; }
  a.orders-nav__item.active {
    background: #93a1a1; }
  a.orders-nav__item .name {
    text-align: center;
    font-weight: 600;
    width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-left: 0.1em; }
  a.orders-nav__item .complete-icon {
    font-size: 0.8em;
    line-height: 0.9em; }
  a.orders-nav__item .status {
    text-align: center;
    font-size: 0.7em; }
  a.orders-nav__item .date {
    text-align: center; }
  a.orders-nav__item .time {
    text-align: center;
    font-size: 0.8em; }
  a.orders-nav__item .new-order-label {
    text-align: center; }
    a.orders-nav__item .new-order-label .text {
      display: inline-block;
      background: green;
      border-radius: 5px;
      color: white;
      padding: 0 0.4em; }
  a.orders-nav__item .need-action-order-label {
    text-align: center; }
    a.orders-nav__item .need-action-order-label .text {
      display: inline-block;
      background: #ffb300;
      border-radius: 5px;
      color: white;
      padding: 0 0.4em; }

@charset "UTF-8";
.foh-order {
  background-color: #fdf6e3;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='396' height='396' viewBox='0 0 100 100'%3E%3Cg stroke='%2393a1a1' stroke-width='0' %3E%3Crect fill='%23eee8d5' x='-60' y='-60' width='110' height='240'/%3E%3C/g%3E%3C/svg%3E");
  color: #073642;
  height: 100%;
  padding-bottom: 200px; }
  .foh-order .analytics.pane {
    padding: 1em 0.5em;
    background: #002b36;
    color: #eee8d5; }
    .foh-order .analytics.pane .attr {
      display: inline-block;
      width: 5em; }
    .foh-order .analytics.pane h3 {
      font-weight: 600; }
  .foh-order .app-controls {
    text-align: center; }
  .foh-order .print-button {
    width: 10em; }
  .foh-order .show-older-items-action {
    padding: 1em;
    font-weight: 600; }
  .foh-order .pane {
    background: #fdf6e3;
    margin: 0.3em 0.2em;
    line-height: 1.25em;
    padding: 0.2em;
    border-radius: 5px;
    box-shadow: 0px 2px 8px #586e75; }
  .foh-order .order-type .token {
    padding: 0.2em;
    font-weight: 600;
    border-radius: 0.3em;
    margin-right: 0.3em;
    display: inline-block; }
  .foh-order .top-controls {
    display: flex;
    flex-wrap: wrap; }
    .foh-order .top-controls .right {
      flex: 1 1; }
      .foh-order .top-controls .right button {
        margin-right: 1em; }
  .foh-order .scheduled-text {
    background: none; }
    .foh-order .scheduled-text .bp3-icon-time {
      position: relative;
      top: -0.3em;
      margin-right: 0.2em; }
  .foh-order .customer-info .basic-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
  .foh-order .customer-info .tip {
    background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
    padding: 0.1em 0.3em;
    border-radius: 0.5rem;
    margin-right: 0.3em;
    font-weight: 600; }
  .foh-order .delivery-info {
    font-size: 1em;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: baseline;
    grid-column-gap: 0.2em; }
  .foh-order .attr-name {
    font-weight: 600; }
  .foh-order .items-group {
    margin: 0.5em 0; }
    .foh-order .items-group .items-group-name {
      font-size: 1em;
      font-weight: 600;
      color: #6c71c4; }
      .foh-order .items-group .items-group-name:before {
        content: "❖ "; }
    .foh-order .items-group .group-items {
      margin-left: 3px; }
  .foh-order .order-item {
    align-items: flex-start;
    border-bottom: none !important; }
    .foh-order .order-item > .text {
      border-bottom: 2px solid #839496; }
  .foh-order .order-item:nth-of-type(2n+1) {
    background: none; }
  .foh-order .quantity {
    margin-right: 0.1em; }
    .foh-order .quantity .text {
      border-radius: 0.1em;
      display: inline-block;
      background: #dc322f;
      color: #eee8d5;
      padding: 0 0.3em;
      font-weight: 600; }
    .foh-order .quantity.single-quantity .text {
      background: none;
      font-weight: 400;
      color: #002b36; }
  .foh-order .order-item-details .name {
    font-weight: 600; }
  .foh-order .utensils-option {
    font-weight: 600;
    font-size: 1.2em;
    padding: 0.3em; }
  .foh-order .category {
    color: #586e75;
    font-weight: 600;
    font-size: 0.9em;
    /* background: #cbc6d2; */ }
  .foh-order .modgroup {
    margin-left: 0.5em; }
    .foh-order .modgroup .items- {
      text-transform: none;
      color: #d33682;
      font-size: 0.8em; }
  .foh-order .order-totals {
    font-size: 0.9em;
    justify-content: end;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 0.15em 1em; }
  .foh-order .payment {
    font-size: 0.9em;
    /* background: #ccff66; */
    /* color: white; */
    padding: 0.2em 0.3em;
    text-align: right; }
  .foh-order .order-actions {
    display: flex;
    flex-wrap: wrap; }
    .foh-order .order-actions button {
      margin-right: 0.5em; }

.night img {
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5); }

.night .foh-order {
  background: #586e75;
  color: #eee8d5;
  height: 100vh; }
  .night .foh-order .pane {
    background: #073642; }

.new-order-alert__content {
  border-bottom: 2px solid black;
  font-size: 0.8em;
  padding: 0.4em 0;
  background: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center; }
  .new-order-alert__content.new-order {
    -webkit-animation: flash 2s infinite;
            animation: flash 2s infinite; }
  .new-order-alert__content .alert-text {
    padding: 0;
    margin: 0; }
    .new-order-alert__content .alert-text.no-notifications {
      font-style: italic;
      color: gray; }
    .new-order-alert__content .alert-text li {
      text-decoration: underline;
      font-size: 1.5em; }

@-webkit-keyframes flash {
  0% {
    backgound: white; }
  50% {
    background: yellow; }
  0% {
    backgound: white; } }

@keyframes flash {
  0% {
    backgound: white; }
  50% {
    background: yellow; }
  0% {
    backgound: white; } }

.control-center-wrapper {
  padding: 0.4em 0.2em 0.4em 0.2em;
  border-bottom: 1px solid gray;
  background: whitesmoke; }
  .control-center-wrapper.inactive {
    background: red;
    color: white; }

.text-support {
  font-size: 0.5em;
  text-align: center;
  align-self: center; }

.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: baseline; }
  .controls .widget {
    margin: 0.2em 0.5em; }

.time-select {
  font: inherit;
  width: 5em;
  font-size: 0.9em; }

.cc-button {
  border-radius: 1em;
  box-shadow: 0;
  border: 3px solid white;
  font-size: 0.5em;
  font-weight: 600; }

.offline-overlay {
  text-align: center; }

.menu-update-button {
  margin: 0.5em;
  padding: 0.5em;
  border-radius: 1em;
  box-shadow: 0;
  border: 3px solid white;
  font-size: 0.5em;
  font-weight: 600; }

.settings {
  font-size: 0.8em;
  margin: 1em 0.5em;
  display: flex;
  flex-wrap: wrap; }

.settings-nav {
  width: 100%; }

.settings-nav-link {
  display: inline-block;
  font-weight: 600;
  padding: 0.3em 0.4em;
  text-decoration: underline;
  text-transform: uppercase; }
  .settings-nav-link.active {
    background: #bada55;
    color: white; }

.settings h2 {
  width: 100%;
  /* border-bottom: 2px solid black */
  font-weight: 900;
  text-transform: uppercase;
  color: white;
  padding: 0.1em 0.3em;
  background: #000000; }

.settings p {
  width: 100%;
  margin: 1em auto; }

.settings .input-group {
  flex: 1 1;
  margin: 0.5em 0em;
  margin-right: 0.2em;
  background: rgba(0, 0, 0, 0.1);
  padding: 0.3em; }

.settings label {
  font-weight: 600;
  display: block;
  text-decoration: underline; }

input:disabled {
  border: none; }

input {
  font: inherit;
  border: 1px solid black; }

.snap-array-editor {
  flex: 1 1;
  display: flex;
  flex-wrap: wrap; }

.snap-array-item {
  /* border: 1px solid black; */
  background: rgba(0, 0, 0, 0.1);
  margin: 0.5em 0;
  margin-right: 0.2em;
  flex: 1 1;
  /* margin-left: 0.5em; */ }

.new-item-button {
  width: 100%;
  padding: 0.5em 0;
  font: inherit;
  background: #66ccff;
  color: white; }

.snap-schedule-editor select {
  font: inherit;
  margin: 0 0.2em; }

.day-of-week {
  width: 3em; }

.day-of-week-editor {
  display: flex;
  flex-wrap: wrap;
  margin: 0.2em 0;
  /* background: 1px solid rgba(0,0,0,0.3); */ }

.time-range-editor {
  display: flex;
  flex-wrap: wrap; }

.sync-error {
  max-width: 600px;
  padding: 1em;
  background: pink; }

.order-item-transition-enter {
  opacity: 0;
  z-index: 1; }
  .order-item-transition-enter.order-item-transition-enter-active {
    opacity: 1;
    transition: all 250ms ease-in; }

.order-item-transition-leave {
  opacity: 1;
  z-index: 0; }
  .order-item-transition-leave.order-item-transition-leave-active {
    opacity: 0;
    transition: all 250ms ease-in; }

.menu-updater .path-separator {
  margin: 0 10px; }

.menu-updater .navigation {
  padding: 0.2em;
  display: flex;
  margin-bottom: 20px; }

.menu-updater h1 {
  font-weight: 800; }

.menu-updater h2 {
  padding: 0.2em;
  font-weight: 600; }

.menu-updater .menu-item {
  padding: 0.5em 0.2em;
  display: flex;
  align-items: baseline; }
  .menu-updater .menu-item:nth-child(odd) {
    background: whitesmoke; }

.menu-updater .category-button-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 20px; }

.menu-updater .category-button {
  display: block;
  width: 33%;
  height: 100px;
  font-size: 1em;
  font-weight: 600; }

.menu-updater .category-button-link {
  display: flex;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: black;
  background: #d0f5ff;
  text-align: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  border-radius: 1em;
  box-shadow: 0;
  border: 1px solid #ccdbe0; }

.menu-updater .section-heading {
  background-color: black;
  color: white;
  margin: 0.4em 0; }

