.menu-updater {
    // padding: 0.2em;
    .path-separator {
        margin: 0 10px;
    }
    .navigation {
        padding: 0.2em;
        display: flex;
        // justify-content: space-between;
        margin-bottom: 20px;
    }
    h1 {
        font-weight: 800;
    }
    h2 {
        padding: 0.2em;
        font-weight: 600;
    }
    .menu-item {
        padding: 0.5em 0.2em;
        display: flex;
        align-items: baseline;
        &:nth-child(odd) {
            background: whitesmoke;
        }
    }
    .category-button-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: 20px;
    }
    .category-button {
        display: block;
        width: 33%;
        height: 100px;
    //    margin: 0.5em;
        font-size: 1em;
        font-weight: 600;
    }
    .category-button-link {
        display: flex;
        width: 100%;
        height: 100%;
        text-decoration: none;
        color: black;
        background: rgb(208, 245, 255);
        text-align: center;
        align-self: center;
        justify-content: center;
        align-items: center;
        border-radius: 1em;
        box-shadow: 0;
        border: 1px solid rgb(204, 219, 224);
        
    }
    .category-button-text {

    }
    .section-heading {
        background-color: black;
        color: white;
        margin: 0.4em 0;
    }
}