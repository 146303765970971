.control-center-wrapper {
  padding: 0.4em 0.2em 0.4em 0.2em;
  border-bottom: 1px solid gray;
  background: whitesmoke;
  // display: flex;
  // align-items:baseline;
  // flex-wrap: wrap;
  &.inactive {
    background: red;
    color: white;
  }
}
.text-support {
  font-size: 0.5em;
  text-align: center;
  align-self: center;
}
.controls {
  // width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items:baseline;
  .widget {
    margin: 0.2em 0.5em;
    // background-color: white;
  }
}
.time-select {
  font: inherit;
  width: 5em;
  font-size: 0.9em;
}


.cc-button {
  // padding: 1em 0.5em;
  border-radius: 1em;
  box-shadow: 0;
  border: 3px solid white;
  font-size: 0.5em;
  font-weight: 600;

}
.offline-overlay {
  text-align: center;
}

.menu-update-button {
  margin: 0.5em;
  padding: 0.5em;
  border-radius: 1em;
  box-shadow: 0;
  border: 3px solid white;
  font-size: 0.5em;
  font-weight: 600;
}